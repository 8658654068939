import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import Error from '../Error';
import EditStatus from '../EditStatus';
import ReelTypesEditMaterialList from './ReelTypesEditMaterialList';


import { useSessionContext } from '../../contexts/SessionContext';

export default function ReelTypesEdit(props) {
    //console.debug("RTS Break ReelTypesEdit");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const mode = props.mode;
    //const display = props.display;
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [idGuid, setIdGuid] = useState('');
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [idSap, setIdSap] = useState('');
    const [material, setMaterial] = useState(null);
    const [reelTypeImage, setReelTypeImage] = useState('');
    const [alumAssoc, setAlumAssoc] = useState('');
    const [flangeSize, setFlangeSize] = useState('');
    const [traverseSize, setTraverseSize] = useState('');
    const [drumSize, setDrumSize] = useState('');
    const [maxOverallWidth, setMaxOverallWidth] = useState('');
    const [arborHoleSize, setArborHoleSize] = useState('');
    const [weightCapacity, setWeightCapacity] = useState('');
    const [approxWeight, setApproxWeight] = useState(0);
    const [status, setStatus] = useState(1);



    const fetchData = useCallback(() => {
        let apiURL = apiRoot + "/ReelTypes/GetReelTypeById/" + props.idGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);

                setIdGuid(response.data.idGuid)
                setName(response.data.name);
                setDesc(response.data.desc!==null?response.data.desc:'');
                setIdSap(response.data.idSap!==null?response.data.idSap:'');
                setMaterial(response.data.material);
                setReelTypeImage(response.data.reelTypeImage!==null?response.data.reelTypeImage:'');
                setAlumAssoc(response.data.alumAssoc!==null?response.data.alumAssoc:'');
                setFlangeSize(response.data.flangeSize!==null?response.data.flangeSize:'');
                setTraverseSize(response.data.traverseSize!==null?response.data.traverseSize:'');
                setDrumSize(response.data.drumSize!==null?response.data.drumSize:'');
                setMaxOverallWidth(response.data.maxOverallWidth!==null?response.data.maxOverallWidth:'');
                setArborHoleSize(response.data.arborHoleSize!==null?response.data.arborHoleSize:'');
                setWeightCapacity(response.data.weightCapacity!==null?response.data.weightCapacity:'');
                setApproxWeight(response.data.approxWeight);
                setStatus(response.data.status);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token, props.idGuid]);

    
    useEffect(() => {
        if(mode === 1){
            fetchData();
        }
        else{
            setIsLoading(false);
        }

    }, [mode, fetchData]);
    
    
    function handleSubmit(e) {
        e.preventDefault();

        if(name === null){
            setErrorTitle("Validation Error");
            setErrorBody("Name is required");
            setShowErrorModal(true);
            return;
        }

        if(drumSize === null){
            setErrorTitle("Validation Error");
            setErrorBody("Drum Size is required");
            setShowErrorModal(true);
            return;
        }

        saveData();
    }    


    function saveData() {
        let apiObj = {
            IdGuid: idGuid,
            IdSap: idSap===''?null:idSap,
            Name: name===''?null:name,
            Desc: desc===''?null:desc,
            Material: material===''?null:material,
            AlumAssoc: alumAssoc===''?null:alumAssoc,
            FlangeSize: flangeSize===''?null:flangeSize,
            TraverseSize: traverseSize===''?null:traverseSize,
            DrumSize: drumSize===''?null:drumSize,
            MaxOverallWidth: maxOverallWidth===''?null:maxOverallWidth,
            ArborHoleSize: arborHoleSize===''?null:arborHoleSize,
            WeightCapacity: weightCapacity===''?null:weightCapacity,
            ApproxWeight: approxWeight===''?0:approxWeight,
            ReelTypeImage: reelTypeImage===''?null:reelTypeImage,
            Status: status,
            modifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/ReelTypes/UpdateReelType/" + idGuid;
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateReelType Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/ReelTypes/CreateReelType";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
                )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateReelType Error", error);
                setShowErrorModal(true);
            });
        }
    } 


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }    


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Box>
            <form id="reelTypesForm" onSubmit={handleSubmit}>
                <Box maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box maxWidth="sm" sx={{width:smallForm?'auto':'600px'}}>
                <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {props.setFormDirtyFx(1); setName(e.target.value);}}
                />
                <TextField
                    id="tbDesc"
                    margin="normal"
                    fullWidth
                    label="Description"
                    size="small"
                    required
                    value={desc}
                    onChange={(e) => {props.setFormDirtyFx(1); setDesc(e.target.value);}}
                />
                <TextField
                    id="tbIdSap"
                    margin="normal"
                    fullWidth
                    label="SAP Id"
                    size="small"
                    value={idSap}
                    onChange={(e) => {props.setFormDirtyFx(1); setIdSap(e.target.value);}}
                />
                <TextField
                    id="tbIdImage"
                    margin="normal"
                    fullWidth
                    label="Image URL"
                    size="small"
                    value={reelTypeImage}
                    onChange={(e) => {props.setFormDirtyFx(1); setReelTypeImage(e.target.value);}}
                />
                <ReelTypesEditMaterialList material={material} setMaterialFx={setMaterial} setFormDirtyFx={props.setFormDirtyFx} />
                <TextField
                    id="tbAlumAssoc"
                    margin="normal"
                    fullWidth
                    label="Alum. Assoc. Designation"
                    size="small"
                    value={alumAssoc}
                    onChange={(e) => {props.setFormDirtyFx(1); setAlumAssoc(e.target.value);}}
                />
                <EditStatus status={status} setStatusFx={setStatus} setFormDirtyFx={props.setFormDirtyFx} /> 
                <Typography variant="h6" sx={{mt:1}}>Dimensions (inches)</Typography>
                <Box sx={{display:'flex'}}>
                    <Box sx={{flex:1, mr:2}}>
                        <TextField
                            id="tbFlangeSize"
                            margin="normal"
                            fullWidth
                            label="Flange"
                            size="small"
                            value={flangeSize}
                            onChange={(e) => {props.setFormDirtyFx(1); setFlangeSize(e.target.value);}}
                        />
                        <TextField
                            id="tbTraverseSize"
                            margin="normal"
                            fullWidth
                            label="Traverse"
                            size="small"
                            value={traverseSize}
                            onChange={(e) => {props.setFormDirtyFx(1); setTraverseSize(e.target.value);}}
                        />
                        <TextField
                            id="tbDrumSize"
                            margin="normal"
                            fullWidth
                            required
                            label="Drum Size"
                            size="small"
                            value={drumSize}
                            onChange={(e) => {props.setFormDirtyFx(1); setDrumSize(e.target.value);}}
                        />
                    </Box>
                    <Box sx={{flex:1}}>
                        <TextField
                            id="tbOverallWidth"
                            margin="normal"
                            fullWidth
                            label="Max Overall Width"
                            size="small"
                            value={maxOverallWidth}
                            onChange={(e) => {props.setFormDirtyFx(1); setMaxOverallWidth(e.target.value);}}
                        />
                        <TextField
                            id="tbArborHoleSize"
                            margin="normal"
                            fullWidth
                            label="Arbor Hole"
                            size="small"
                            value={arborHoleSize}
                            onChange={(e) => {props.setFormDirtyFx(1); setArborHoleSize(e.target.value);}}
                        />
                    </Box>
                </Box>
                <Typography variant="h6" sx={{mt:1}}>Weights (lbs)</Typography>
                <Box sx={{display:'flex'}}>
                    <Box sx={{flex:1, mr:2}}>
                        <TextField
                            id="tbWeightCapacity"
                            margin="normal"
                            fullWidth
                            label="Weight Capacity"
                            size="small"
                            value={weightCapacity}
                            onChange={(e) => {props.setFormDirtyFx(1); setWeightCapacity(e.target.value);}}
                        />
                    </Box>
                    <Box sx={{flex:1}}>
                        <TextField
                            id="tbApproxWeight"
                            margin="normal"
                            fullWidth
                            label="Approximate Weight"
                            size="small"
                            value={approxWeight}
                            onChange={(e) => {props.setFormDirtyFx(1); setApproxWeight(e.target.value);}}
                        />
                    </Box>
                </Box> 
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained" disabled={props.formDirty===1?false:true} type="submit" sx={{mr:2}}>Save</Button>
                </Box>
                </Box>
                </Box>
            </form>
        </Box>
        </>
    );
}


