import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSessionContext } from '../../contexts/SessionContext';


export default function NavButManage( props ){
    //console.debug("RTS Break NavButManage");

    const history = useNavigate();
    const [open, setOpen] = useState(false);
    const [anchorElManage, setAnchorElManage] = useState(null);
    const {sessionCustomer, setCurFilter} = useSessionContext();

    const handleOpenManageMenu = (event) => {
        setOpen(!open);
        setAnchorElManage(event.currentTarget);
    };
      
    const handleCloseManageMenu = () => {
        props.setOpenDrawerFx(false);
        setAnchorElManage(null);
    };

    function handleProjectsClick(e){
        setCurFilter(null);
        props.setOpenDrawerFx(false);
        handleCloseManageMenu();
        history("/Projects");
    }


    function handlePickupRequestsClick(e){
        setCurFilter(null);
        props.setOpenDrawerFx(false);
        handleCloseManageMenu();
        history("/PickupRequests");
    }


    function handleCrewMembersClick(e){
        setCurFilter(null);
        props.setOpenDrawerFx(false);
        handleCloseManageMenu();
        history("/CrewMembers");
    }    


    if(sessionCustomer.accessLevel < 50){
        return("");
    }


    if(props.mode==="NavDrawer"){
        return (
            <>
            <ListItem button key="Manage" onClick={handleOpenManageMenu} sx={{cursor:"pointer"}}>
                <ListItemText primary="Manage" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button key="Projects" sx={{ pl:4, cursor:"pointer" }}  onClick={handleProjectsClick}>
                        <ListItemText primary="Projects" />
                    </ListItem>
                    <ListItem button key="PickupRequests" sx={{ pl:4, cursor:"pointer" }}  onClick={handlePickupRequestsClick} >
                        <ListItemText primary="Reel Pickup Requests" />
                    </ListItem>
                    <ListItem button key="CrewMembers" sx={{ pl:4, cursor:"pointer" }}  onClick={handleCrewMembersClick}>
                        <ListItemText primary="Crew Members" />
                    </ListItem>
                </List>
            </Collapse>

            </>
        )
    }
    
    return(
        <>
        <Button
            key='Manageistration'
            onClick={handleOpenManageMenu}
            sx={{ my: 2, mx: 1, color: 'white', textTransform: 'unset' }}
            endIcon={<ArrowDropDownIcon />}
        >
            Manage
        </Button>

        <Menu
            id="menu-Manage"
            anchorEl={anchorElManage}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={Boolean(anchorElManage)}
            onClose={handleCloseManageMenu}
            sx={{
                display: { xs: 'none', md: 'block' },
            }}
        >
            <MenuItem key='Projects' onClick={handleProjectsClick}>
                <Typography textAlign="center">Projects</Typography>
            </MenuItem>
            <MenuItem key='PickupRequests' onClick={handlePickupRequestsClick}>
                <Typography textAlign="center">Reel Pickup Requests</Typography>
            </MenuItem>
            <MenuItem key='CrewMembers' onClick={handleCrewMembersClick}>
                <Typography textAlign="center">Crew Members</Typography>
            </MenuItem>
        </Menu>
        </>
    );
}



