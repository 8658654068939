import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Image from 'mui-image';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import Loading from '../components/Loading';
import { useSessionContext } from '../contexts/SessionContext';

import Copyright from '../components/Copyright';
import ForgotPassword from '../components/Login/ForgotPassword';
import PasswordReset from '../components/PasswordReset';
import logo from './logo-Southwire.png';


export default function LoginPage( props ){
    //console.debug("RTS Break LoginPage");

    const {setUser, setAppMode, setUserAuthorized, setUserAuthToken, setSessionCustomer, setPrevSessionCustomer, apiRoot } = useSessionContext();

    const userRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");

    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    //const [resetPassword, setResetPassword] = useState(props.pwr!==null?props.pwr:false);
    //const [initPassword, setInitPassword] = useState(props.pwi!==null?props.pwi:false)


    useEffect(() =>{
        if(props.pwr!==true){
            userRef.current.focus();
        }
        else{
            setShowPasswordModal(true);
        }
    }, [props])


    // function setInitResetPassword(val){
    //     setResetPassword(val);
    //     setInitPassword(val);
    // }


    function handleSubmit(e) {
        e.preventDefault();

        setIsLoading(true);
        
        let authParms = {
            "username": userId,
            "password": password
        }
   
        let apiURL = apiRoot + "/Authentication/login";
        axios
            .post(
                apiURL,
                authParms, 
                {httpsAgent: {rejectUnauthorized: false}}
            )
            .then(res => {
                setIsLoading(false);

                if (res.status === 200 && res.data.token) {
                    try{
                        setUserAuthToken({'token': res.data.token});
                    }
                    catch(err){
                        console.log("Token Error");
                        console.log(err);
                    }

                    try{
                        setUser(res.data.user);
                    }
                    catch(err){
                        console.log("User Data Error");
                        console.log(err);
                    }

                    try{
                        setUserAuthorized(true);
                    }
                    catch(err){
                        console.log("Authorization Error");
                        console.log(err);
                    }

                    try{
                        let cusCount = res.data.user.appUserCustomers.length;
                        if(cusCount === 1){
                            setSessionCustomer(res.data.user.appUserCustomers[0]);
                            setPrevSessionCustomer(res.data.user.appUserCustomers[0]);
                        }
                    }
                    catch(err){
                        console.log("Session Customer Error");
                        console.log(err);
                    }

                    let modCount = 0;
                    let defMode = '';
                    if(res.data.user.modReelsFlag === 1){
                        modCount++;
                        if(defMode === ''){
                            defMode = 'REELS';
                        }
                    }

                    if(res.data.user.modWorkSetsFlag === 1){
                        modCount++;
                        if(defMode === ''){
                            defMode = 'WORK';
                        }
                    }

                    if(res.data.user.modPalletsFlag === 1){
                        modCount++;
                        if(defMode === ''){
                            defMode = 'PALLETS';
                        }
                    }

                    if(res.data.user.modManufacturingFlag ===1){
                        modCount++;
                        if(defMode === ''){
                            defMode = 'MANUFACTURING';
                        }
                    }

                    if(res.data.user.modReelInspectionsFlag ===1){
                        modCount++;
                        if(defMode === ''){
                            defMode = 'REELINSPECTIONS';
                        }
                    }

                    if(modCount === 1){
                        try{
                            setAppMode(defMode);

                            if(defMode === 'PALLETS' || defMode === 'REELINSPECTIONS' || defMode === 'MANUFACTURING'){
                                setSessionCustomer({accessLevel:100, customer:{idGuid:'-99', idSap:'', name:'None'}});
                                setPrevSessionCustomer({accessLevel:100, customer:{idGuid:'-99', idSap:'', name:'None'}});
                            }
                        }
                        catch(err){
                            console.log("Session Application Mode Error");
                            console.log(err);
                        }                        
                    }

                    console.log(res.data.user);
                }
                else{
                    setUserAuthorized(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
                setUserAuthorized(false);
                setErrorText("Unable to authorize user");
                console.log("Authentification Error", error);
            });
    } 


    if(isLoading){
        return(
            <Loading />
        );
    }


    if(showPasswordModal){
        return(
            <PasswordReset mode={-1} userName={props.pwu} passwordToken={props.pwt} setShowPasswordModalFx={setShowPasswordModal} />
        )
    }

    
    if(showForgotPasswordModal){
        return(
            <ForgotPassword setShowForgotPasswordModalFx={setShowForgotPasswordModal} pwu={props.pwu} pwt={props.pwt} />
        );
    }


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Box>
                    <Typography component="h1" variant="h5">
                        Reel Tracking System
                    </Typography>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <Typography sx={{fontSize:'.5em', fontWeight:'bold', textAlign:'right', width:'60%'}}>
                            Powered By
                        </Typography>
                        <Image src={logo} wrapperStyle={{justifyContent:'left', width:'30%', paddingLeft:'5px'}} style={{width:"100%"}} />
                    </Box>
                </Box>
                <Box>
                    {errorText===""? "": <Typography color="red" variant="h6" sx={{mt:1}}>Unable to authorize user</Typography>}
                </Box>            
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={userId}
                        onChange={(e) => {setUserId(e.target.value);}}
                        ref={userRef}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => {setPassword(e.target.value);}}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" onClick={() => {setShowForgotPasswordModal(true)}} variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      );
}