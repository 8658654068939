import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit'; 

export default function ProductAliasList(props){
    //console.debug("RTS Debug ProductAliasList");

    const history = useNavigate();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));


    function handleClick(e, cellValues){
        history("/ProductAliasEdit", {state: {idGuid:cellValues.row.idGuid, display:cellValues.row.idSap, mode:1}});
    }
 
    const columns = [
        {
            field: "Edit",
            headerName: "",
            description:"Edit Product", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <EditIcon />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'idSap', 
            headerName: "Product #", 
            description:"Southwire Product Number", 
            flex:2, 
            hideable:false,
        },
        {
            field: 'productName', 
            headerName: "Product Name", 
            description:"Southwire Product Name", 
            flex:4, 
        },        
        {
            field: 'customerMaterialNo', 
            headerName: "Cust. Part #", 
            description:"Customer Material Number", 
            flex:2,
            hideable:false,
        },        
        {
            field: 'alias', 
            headerName: "Cust. Part Name", 
            description:"Customer Part Name", 
            flex:4
        },
        {
            field: 'type', 
            headerName: "Type", 
            description:"Project Status",
            flex:2,    
            renderCell: (cellValues) => {
                if(cellValues.value === 1){
                    return ('Wildcard');
                }
                else{
                    return('Standard');
                }
            },     
        },
        {
            field: 'wildcard', 
            headerName: "Wildcard", 
            description:"Wildcard", 
            flex:2
        },                 
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {alias:false, productName:false, type:false, wildcard:false}
        }
        else{
            return {productName:false, type:false, wildcard:false}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    return (
        <Box sx={{width:'100%', height:'100%', minHeight:'400px'}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                
            />
        </Box>
    );
}
