import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit'; 

export default function UsersList(props){
    //console.debug("RTS Break UsersList");

    const history = useNavigate();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));


    function handleClick(e, cellValues){
        history("/UsersEdit", {state:{idGuid:cellValues.row.id, display:cellValues.row.userName, mode:1}});
    }

    const columns = [
        {
            field: '',
            description:"Edit User", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <EditIcon />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true,
        },
        {
            field: 'firstName', 
            headerName: "First Name", 
            description:"User First Name", 
            flex:2,
        },        
        {
            field: 'lastName', 
            headerName: "Last Name", 
            description:"User Last Name", 
            flex:2, 
        },
        {
            field: 'email', 
            headerName: "Email", 
            description:"User Email", 
            flex:3, 
            hideable:false,
        },
        {
            field: 'status', 
            headerName: "Status", 
            description:"User Status", 
            flex:2,
            valueGetter: (value, row) => {
                let retValue = "Unknown";
                switch(row.status){
                    case 1:
                        retValue = "Active";
                        break;

                    case 0:
                        retValue = "Disabled";
                        break;

                    default:
                        retValue = "Unknown";
                        break;
                }

                return retValue;
            },
        },
        {
            field: 'licensedBy', 
            headerName: "Licensed By", 
            description:"Customer Licnese Used", 
            flex:3,
            valueGetter: (value, row) => {
                return(row.licensedBy!==null?row.licensedBy.name:'');
            },                           
        },
        {
            field: 'adminFlag', 
            headerName: "Admin", 
            description:"User Admin Flag", 
            flex:3,
            valueGetter: (value, row) => {
                let retValue = "Unknown";
                switch(row.adminFlag){
                    case 1:
                        retValue = "Yes";
                        break;

                    case 0:
                        retValue = "No";
                        break;

                    default:
                        retValue = "Unknown";
                        break;
                }

                return retValue;
            },                     
        },
        {
            field: 'reelAdminFlag', 
            headerName: "Reel Admin", 
            description:"User Reel Admin Flag", 
            flex:3,
            valueGetter: (value, row) => {
                let retValue = "Unknown";
                switch(row.reelAdminFlag){
                    case 1:
                        retValue = "Yes";
                        break;

                    case 0:
                        retValue = "No";
                        break;

                    default:
                        retValue = "Unknown";
                        break;
                }

                return retValue;
            },                     
        },
        {
            field: 'southwireFlag', 
            headerName: "Southwire User", 
            description:"Southwire User Flag", 
            flex:3,
            valueGetter: (value, row) => {
                let retValue = "Unknown";
                switch(row.southwireFlag){
                    case 1:
                        retValue = "Yes";
                        break;

                    case 0:
                        retValue = "No";
                        break;

                    default:
                        retValue = "Unknown";
                        break;
                }

                return retValue;
            },                     
        },                  
        {
            field: 'distributorFlag', 
            headerName: "Distributor", 
            description:"User Distributor Flag", 
            flex:3,
            valueGetter: (value, row) => {
                let retValue = "Unknown";
                switch(row.distributorFlag){
                    case 1:
                        retValue = "Yes";
                        break;

                    case 0:
                        retValue = "No";
                        break;

                    default:
                        retValue = "Unknown";
                        break;
                }

                return retValue;
            },                     
        },
        {
            field: 'manufacturerFlag', 
            headerName: "Manufacturer", 
            description:"User Manufacturer Flag", 
            flex:3,
            valueGetter: (value, row) => {
                let retValue = "Unknown";
                switch(row.manufacturerFlag){
                    case 1:
                        retValue = "Yes";
                        break;

                    case 0:
                        retValue = "No";
                        break;

                    default:
                        retValue = "Unknown";
                        break;
                }

                return retValue;
            },                     
        },                         
        {
            field: 'createDate', 
            headerName: "Create Date", 
            description:"Date User was created", 
            flex:3,
            valueGetter: (value, row) => {
                if(value == null){
                    return '';
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },                   
        },        
        {
            field: 'modifyDate', 
            headerName: "Modify Date", 
            description:"Date User was last modified", 
            flex:3,
            valueGetter: (value, row) => {
                if(value == null){
                    return '';
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },                  
        },        
        {
            field: 'modifyUser', 
            headerName: "Modify User", 
            description:"User who performed last modification", 
            flex:3,                             
        },        

    ];


    function getVisibilityModel(){
        if(smallForm){
            return {firstName:false, lastName:false, licensedBy:false, adminFlag:false, reelAdminFlag:false, southwireFlag:false, distributorFlag:false, manufacturerFlag:false, createDate:false, modifyDate:false, modifyUser:false}
        }
        else{
            return {adminFlag:false, reelAdminFlag:false, southwireFlag:false, distributorFlag:false, manufacturerFlag:false, createDate:false, modifyDate:false, modifyUser:false}
        }
    }


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    }


    return (
        <Box sx={{width:'100%', height:'100%', minHeight:'400px'}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.email}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 
                }}                
            />
        </Box>
    );
}