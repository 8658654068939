import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../components/Loading';
import Error from '../components/Error';
import CrewMembersList from '../components/CrewMembers/CrewMembersList';
import CrewMembersHeader from '../components/CrewMembers/CrewMembersHeader';

import { useSessionContext } from '../contexts/SessionContext';

export default function CrewMembersPage( props ){
    //console.debug("RTS Break CrewMembersPage");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [data, setData] = useState([]);


    const fetchData = useCallback((pFilter) => {
        let apiURL = apiRoot + "/AppUsers/GetCrewMembersSummary";
        axios
        .post(
            apiURL,
            pFilter, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);
 
    
    useEffect(() => {
        props.setIsModalFx(false);
        fetchData({user:user, customer:sessionCustomer.customer, sortBy:0});
    }, [user, sessionCustomer, fetchData, props]);  

 
    const setTableHeight = () => {
        let retValue;

        if(smallForm){
            retValue = 'auto';
        }
        else{
            retValue = '75vh';
        }
        return retValue;
    };    


    if(hasError){
        return(
            <Error />
        );
    };


    if(isLoading){
        return(
            <Loading />
        );
    };


    return(
        <Box>
            <CrewMembersHeader />
            <Box sx={{mt:2, height:setTableHeight(), minHeight:'500px'}}>           
                <CrewMembersList data={data} />
            </Box>
        </Box>
    );
}
