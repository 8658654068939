import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit'; 

export default function ReelsList(props){
    //console.debug("RTS Break ReelsList");

    const history = useNavigate();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));


    function handleClick(e, cellValues){
        history("/ReelsEdit", {state:{idGuid:cellValues.row.idGuid, display:cellValues.row.serialNumber, mode:1}});
    }


    const columns = [
        {
            field: '',
            description:"Edit Reel", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                                <EditIcon />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'serialNumber', 
            headerName: "Serial Number", 
            description:"Reel Serial Number", 
            flex:3,
            hideable:false,
        },
        {
            field: 'reelTypeName', 
            headerName: "Reel Type", 
            description:"Reel Type Name", 
            flex:2,
        },
        {
            field: 'createDate', 
            headerName: "Create Date", 
            description:"Create Date", 
            flex:2,           
            valueGetter: (value, row) => {
                if(value == null){
                    return '';
                }

                return (
                    new Date(value)
                );
            }, 
            type:'date',            
        },
        {
            field: 'createUser', 
            headerName: "Create User", 
            description:"User who created reel", 
            flex:3,         
        },     
        {
            field: 'modifyDate', 
            headerName: "Modify Date", 
            description:"Last Modify Date", 
            flex:2,           
            valueGetter: (value, row) => {
                if(value == null){
                    return '';
                }

                return (
                    new Date(value)
                );
            }, 
            type:'date',            
        },
        {
            field: 'modifyUser', 
            headerName: "Modify User", 
            description:"User who performed last modification", 
            flex:3,         
            hide:true                      
        },                     
        {
            field: 'trackerESN', 
            headerName: "Tracker", 
            description:"Tracker ESN", 
            flex:3,
        },        
        {
            field: 'trackerIdSeq', 
            headerName: "Sequence", 
            description:"Tracker Sequence Id", 
            flex:1,
        },        
        {
            field: 'trackerBatteryLevel', 
            headerName: "Battery Level", 
            description:"Tracker Battery Level", 
            flex:1,
            valueFormatter: (value) => {
                if (value == null) {
                  return '';
                }
                return `${value.toLocaleString()} %`;
            },
            align:'right',
            type:'number',
        },         
        {
            field: 'trackerLastUpdate', 
            headerName: "Last Update", 
            description:"Tracker Last Update", 
            flex:2,
            valueGetter: (value, row) => {
                if(value == null){
                    return '';
                }

                return (
                    new Date(value)
                );
            },
            type:'date',            
        },        
        {
            field: 'status', 
            headerName: "Status", 
            description:"Reel Status", 
            flex:1,
            renderCell: (cellValues) => {
                if(cellValues.value === 1){
                    return ('Active');
                }
                else{
                    return('Disabled');
                }
            }, 
        },        
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {trackerBatteryLevel:false, reelTypeName:false, createDate:false, createUser:false, modifyDate:false, modifyUser:false, trackerIdSeq:false, trackerLastUpdate:false, status:false}
        }
        else{
            return {createDate:false, createUser:false, modifyDate:false, modifyUser:false, trackerIdSeq:false, trackerLastUpdate:false, status:false}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    return (
        <Box sx={{width:'100%', height:'100%', minHeight:'400px'}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                
            />
        </Box>
    );
}
