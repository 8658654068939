import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import Button from '@mui/material/Button';
import { DataGridPro } from '@mui/x-data-grid-pro';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function DashboardOrderList(props) {
    //console.debug("RTS Break DashboardOrderList");

    const {user, sessionCustomer, setCurFilter, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [repoOrders, setRepoOrders] = useState([]);

    const fetchData = useCallback((pFilter) => {
        let apiURL = apiRoot + "/Dashboard/GetDashboardOrders";
        axios
        .post(
            apiURL,
            pFilter,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setRepoOrders(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]); 


    useEffect(() => {
        fetchData({"user": user, "customer": sessionCustomer.customer, "sortBy":0});
    }, [user, sessionCustomer.customer, fetchData]); 


    function handleClick(e, cellValues){
        setCurFilter({key:'LoadListPage', filter:{User:user, 
            Status:[],
            Customer:sessionCustomer.customer,
            Orders:[cellValues.row.order], 
            Projects:[], 
            Products:[], 
            ReelTypes:[],
            Reels:[],
            MinLength:0,
            NamedLocations:[],
            Pinned:-1,
            Locked:-1,
            SortBy:0}
        });        

        history("/LoadList", {state:{}});
    }


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    const columns = [
        {field: 'order', 
            headerName: "Order", 
            description:"Order Number", 
            flex:4,
            renderCell: (cellValues) => {
                return (
                <Button
                    onClick={(event) => {
                    handleClick(event, cellValues);
                    }}
                >
                    {cellValues.row.order}
                </Button>
                );
            }
        },
        {field: 'activeReels', 
            headerName: "Active Reels", 
            description:"Number of Active Reels", 
            flex:1,
            type:"number", 
        },        
        {field: 'reels', 
            headerName: "Reels", 
            description:"Number of Reels", 
            flex:1,
            type:"number",
        }
    ];

    return (
        <DataGridPro 
            rows={repoOrders} 
            columns={columns} 
            getRowId={row => row.order}
            sx={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal"
                },
                "& .MuiDataGrid-columnHeader": {
                  // Forced to use important since overriding inline styles
                  height: "unset !important"
                },
                "& .MuiDataGrid-columnHeaders": {
                  // Forced to use important since overriding inline styles
                  maxHeight: "168px !important"
                }
            }}
        />
    );
}
