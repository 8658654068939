import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function NamedLocationsFilterManagerList(props) {
    //console.debug("RTS Break NamedLocationsFilterManagerList");

    const {sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [manager, setManager] = useState(null);
    const [repoManagers, setRepoManagers] = useState([]);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    const fetchData = useCallback(() => {
        let apiURL = apiRoot + "/Customers/GetCustomerManagers";
        axios
        .post(
            apiURL,
            sessionCustomer.customer,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setRepoManagers(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token, sessionCustomer]);

    
    useEffect(() => {
        setManager(props.manager);
        fetchData();
    }, [props, fetchData]); 


    function valChange(e, val){
        props.setManagerFx(val);
        setManager(val);
        props.setFormDirtyFx(1);
    }


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <>
            <Autocomplete
                options={repoManagers}
                renderInput={(params) => <TextField {...params} label="Manager" size="small" />}
                value={manager}
                onChange={valChange}
                getOptionLabel={(repoValue) => repoValue!=null?repoValue!==''?repoValue.firstName + ' ' + repoValue.lastName:'':''}
                renderOption={(props, repoManagers) => (
                    <Box component="li" {...props} key={repoManagers.userName}>
                        {repoManagers.firstName + ' ' + repoManagers.lastName}
                    </Box>
                )}
                isOptionEqualToValue={(option, value) => option.userName === value.userName}
            />
        </>
    );
}

