import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';


export default function ReelsLoadList(props){
    //console.debug("RTS Break ReelsLoadList");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [data, setData] = useState([]);

    const fetchData = useCallback((pIdGuid) => {
        let apiURL = apiRoot + "/Loads/GetLoadsByReelId/" + pIdGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
                setIsLoading(false);
            }
            else{
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        fetchData(props.idGuid);
    }); 


    const columns = [
        {
            field: 'soldToCustomerName', 
            headerName: "Sold To Customer", 
            description:"Sold To Customer Name", 
            flex:4,
            valueGetter: (value, row) => {
                return row.soldToCustomer.name + " (" + row.soldToCustomer.idSap + ")";
            },
        },    
        {
            field: 'shipDate', 
            headerName: "Ship Date", 
            description:"Date Shipped", 
            flex:2,           
            valueGetter: (value, row) => {
                if(value == null){
                    return '';
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },
        },
        {
            field: 'returnedDate', 
            headerName: "Return Date", 
            description:"Date Returned", 
            flex:2,           
            valueGetter: (value, row) => {
                if(value == null){
                    return '';
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },
        }                    

    ];


    function getVisibilityModel(){
        if(smallForm){
            return {}
        }
        else{
            return {}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return (
        <>
        <Box sx={{textAlign:'left', mt:1}}>
            <Typography variant="h6" sx={{display:'inline'}}>Serial Number:</Typography>
            <Typography inline variant="h6" sx={{display:'inline', ml:1}}>{props.display}</Typography>
        </Box>        
        <Box sx={{width:'100%', height:'100%', minHeight:'400px'}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                
            />
        </Box>
        </>
    );
}
