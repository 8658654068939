import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ProductsEdit(props) {
    //console.debug("RTS Break ProductsEdit");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    //const display = props.display;
    const mode = props.mode;

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [idGuid, setIdGuid] = useState('')
    const [idSap, setIdSap] = useState('');
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [desc, setDesc] = useState('');
    const [productHierarchy, setProductHierarchy] = useState('');
    const [productImage, setProductImage] = useState('');
    const [specsDoc, setSpecsDoc] = useState('');
    const [pullySize, setPullySize] = useState('');
    const [pullyDistance, setPullyDistance] = useState('');
    const [stringInstructions, setStringInstructions] = useState('');
    const [weightPerFt, setWeightPerFt] = useState(0);


    const fetchData = useCallback((pIdGuid) => {
        let apiURL = apiRoot + "/Products/GetProductById/" + pIdGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);

                setIdGuid(response.data.idGuid);
                setIdSap(response.data.idSap);
                setName(response.data.name);
                setShortName(response.data.shortName);
                setDesc(response.data.desc);
                setProductHierarchy(response.data.productHierarchy);
                setProductImage(response.data.productImage);
                setSpecsDoc(response.data.specsDoc);
                setPullySize(response.data.pullySize);
                setPullyDistance(response.data.pullyDistance);
                setStringInstructions(response.data.stringInstructions);
                setWeightPerFt(response.data.weightPerFt);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token, props]);
    

    useEffect(() => {
        if(mode === 1){
            fetchData(props.idGuid);
        }
        else{
            setIsLoading(false);
        }

    }, [props.idGuid, mode, fetchData]);  


    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = {
            IdGuid: idGuid,
            IdSap: idSap===''?null:idSap,
            Name: name===''?null:name,
            ShortName: shortName===''?null:shortName,
            Desc: desc===''?null:desc,
            ProductHierarchy: productHierarchy===''?null:productHierarchy,
            ProductImage: productImage===''?null:productImage,
            SpecsDoc: specsDoc===''?null:specsDoc,
            PullySize: pullySize===''?null:pullySize,
            PullyDistance: pullyDistance===''?null:pullyDistance,
            StringInstructions: stringInstructions===''?null:stringInstructions,
            WeightPerFt: weightPerFt===''?null:weightPerFt,
            modifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/Products/UpdateProduct/" + idGuid;
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateProduct Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/Products/CreateProduct";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateProduct Error", error);
                setShowErrorModal(true);
            });
        }
    }
    
    
    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }    


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Box>
            <form id="productForm" onSubmit={handleSubmit}>
                <Box maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box maxWidth="sm">
                <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {props.setFormDirtyFx(1); setName(e.target.value);}}
                />
                <TextField
                    id="tbShortName"
                    margin="normal"
                    fullWidth
                    label="Short Name"
                    size="small"
                    required
                    value={shortName}
                    onChange={(e) => {props.setFormDirtyFx(1); setShortName(e.target.value);}}
                />
                <TextField
                    id="tbIdSap"
                    margin="normal"
                    fullWidth
                    label="SAP Id"
                    size="small"
                    value={idSap}
                    onChange={(e) => {props.setFormDirtyFx(1); setIdSap(e.target.value);}}
                />
                <TextField
                    id="tbDesc"
                    margin="normal"
                    fullWidth
                    label="Description"
                    size="small"
                    value={desc}
                    onChange={(e) => {props.setFormDirtyFx(1); setDesc(e.target.value);}}
                />
                <TextField
                    id="tbProductHierarchy"
                    margin="normal"
                    fullWidth
                    label="Product Hierarchy"
                    size="small"
                    required
                    value={productHierarchy}
                    onChange={(e) => {props.setFormDirtyFx(1); setProductHierarchy(e.target.value);}}
                />
                <TextField
                    id="tbWeightPerFt"
                    margin="normal"
                    fullWidth
                    label="Weight Per. Ft. (lbs)"
                    size="small"
                    required
                    value={weightPerFt}
                    onChange={(e) => {props.setFormDirtyFx(1); setWeightPerFt(e.target.value);}}
                />    
                <TextField
                    id="tbProductImage"
                    margin="normal"
                    fullWidth
                    label="Product Image URL"
                    size="small"
                    value={productImage}
                    onChange={(e) => {props.setFormDirtyFx(1); setProductImage(e.target.value);}}
                />  
                <TextField
                    id="tbSpecsDoc"
                    margin="normal"
                    fullWidth
                    label="Product Specifications Document URL"
                    size="small"
                    value={specsDoc}
                    onChange={(e) => {props.setFormDirtyFx(1); setSpecsDoc(e.target.value);}}
                />
                <TextField
                    id="tbPullySize"
                    margin="normal"
                    fullWidth
                    label="Pully Size"
                    size="small"
                    value={pullySize}
                    onChange={(e) => {props.setFormDirtyFx(1); setPullySize(e.target.value);}}
                />                                                 
                <TextField
                    id="tbPullyDistance"
                    margin="normal"
                    fullWidth
                    label="Pully Distance"
                    size="small"
                    value={pullyDistance}
                    onChange={(e) => {props.setFormDirtyFx(1); setPullyDistance(e.target.value);}}
                />                                   
                <TextField
                    id="tbStringInstructions"
                    margin="normal"
                    fullWidth
                    label="Stringing Instructions"
                    size="small"
                    value={stringInstructions}
                    onChange={(e) => {props.setFormDirtyFx(1); setStringInstructions(e.target.value);}}
                />                                         
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained" disabled={props.formDirty===1?false:true}  type="submit" sx={{mr:2}}>Save</Button>
                </Box>
                </Box>
                </Box>
            </form>
        </Box>
        </>
    );
}
