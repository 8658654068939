import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import Error from '../Error';
import NamedLocationsEditManagerList from './NamedLocationsEditManagerList';
import NamedLocationsMap from "./NamedLocationsMap";


import { useSessionContext } from '../../contexts/SessionContext';


export default function NamedLocationsEdit(props) {
    //console.debug("RTS Break NamedLocationsEdit");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    //const display = props.display;
    const mode = props.mode;

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [idGuid, setIdGuid] = useState(null);
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');
    const [zip, setZip] = useState('');
    const [geoLat, setGeoLat] = useState('');
    const [geoLng, setGeoLng] = useState('');
    const [manager, setManager] = useState(null);
    const [points, setPoints] = useState([]);


    const fetchData = useCallback((idGuid) => {
        let apiURL = apiRoot + "/NamedLocations/GetLocationById/" + idGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIdGuid(response.data.idGuid);
                setName(response.data.name!==null?response.data.name:'');
                setShortName(response.data.shortName!==null?response.data.shortName:'');
                setAddress1(response.data.address1!==null?response.data.address1:'');
                setAddress2(response.data.address2!==null?response.data.address2:'');
                setCity(response.data.city!==null?response.data.city:'');
                setRegion(response.data.region!==null?response.data.region:'');
                setZip(response.data.zip!==null?response.data.zip:'');
                setGeoLat(response.data.geoLat!==null?response.data.geoLat:'');
                setGeoLng(response.data.geoLng!==null?response.data.geoLng:'');
                setManager(response.data.manager);
                setPoints(response.data.points);

                setIsLoading(false);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]); 
    
    
    useEffect(() => {
        if(mode === 1){
            fetchData(props.idGuid);
        }
        else{
            setIsLoading(false);
        }
    }, [mode, fetchData, props.idGuid]);  


    function handleSubmit(e) {
        e.preventDefault();

        let tmpPoints = [];
        for (var c = 0; c < points.length; c++) {
            tmpPoints.push( { lat: points[c][1], lng: points[c][0], pointSequence: c} );
        }

        let apiObj = {
            IdGuid: idGuid,
            Name: name===''?null:name,
            ShortName: shortName===''?null:shortName,
            Customer: sessionCustomer.customer,
            Address1: address1===''?null:address1,
            Address2: address2===''?null:address2,
            City: city===''?null:city,
            Region: region===''?null:region,
            Zip: zip===''?null:zip,
            GeoLat: geoLat===''?null:geoLat,
            GeoLng: geoLng===''?null:geoLng,
            Manager: manager,
            Points: tmpPoints,
            modifyUser: user.userName,
            createUser: user.userName
        };

        if (apiObj.Name === null || apiObj.Name.trim() === "") {
            setErrorBody("Location name is required");
            setShowErrorModal(true);
            return;
        }

        if (apiObj.ShortName === null || apiObj.ShortName.trim() === "") {
            setErrorBody("Location short name is required");
            setShowErrorModal(true);
            return;
        }

        if (apiObj.Points.length <= 4) {
            setErrorBody("Location must have at least 4 points");
            setShowErrorModal(true);
            return;
        }

        if (mode === 1) {
            let apiURL = apiRoot + "/NamedLocations/UpdateLocation";

            axios
            .put(
                apiURL,
                apiObj,
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
                )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateCustomerReel Error", error);
                setShowErrorModal(true);
            });
        }
        else {
            let apiURL = apiRoot + "/NamedLocations/CreateLocation";

            axios
            .post(
                apiURL,
                apiObj,
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
                )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Create Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateLocation Error", error);
                setShowErrorModal(true);
            });
        }
    }


    if(hasError){
        return(
            <Error />
        );
    };
    
    
    if(isLoading){
        return(
            <Loading />
        );
    };   


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Box>
            <form id="locationForm" onSubmit={handleSubmit}>
                <Box maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box id="smBox" maxWidth="sm" sx={{width:smallForm?'auto':'600px'}}>
                <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {props.setFormDirtyFx(1); setName(e.target.value);}}
                />
                <TextField
                    id="tbShortName"
                    margin="normal"
                    fullWidth
                    label="Short Name"
                    size="small"
                    required
                    value={shortName}
                    onChange={(e) => {props.setFormDirtyFx(1); setShortName(e.target.value);}}
                />
                <NamedLocationsEditManagerList manager={manager} setManagerFx={setManager} setFormDirtyFx={props.setFormDirtyFx} />
                </Box>
                </Box>
                <Box sx={{display:"flex", alignItems:'center', justifyContent:'center', justifyItems:'center', mt:2}}>
                    <NamedLocationsMap pointsFx={setPoints} mode={mode} data={points} formDirty={props.formDirty} setFormDirtyFx={props.setFormDirtyFx} />
                </Box>
                <Box sx={{textAlign:'center', mt:2}}>
                    <Button variant="contained" disabled={props.formDirty===1?false:true} type="submit" sx={{mr:2}}>Save</Button>
                </Box>
            </form>
        </Box>
        </>
    );
}