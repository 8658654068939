import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import Error from '../Error';
import ProjectsEditManagerList from './ProjectsEditManagerList';
import ProjectsEditUnassignedLoadsLookup from './ProjectsEditUnassignedLoadsLookup';
import ProjectsEditLoadsList from './ProjectsEditLoadsList';
import ProjectsEditStatus from './ProjectsEditStatus';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ProjectsEdit(props) {
    //console.debug("RTS Break ProjectsEdit");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const display = props.display;
    const mode = props.mode;

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showLoadsModal, setShowLoadsModal] = useState(false);

    const [idGuid, setIdGuid] = useState(null);
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [manager, setManager] = useState(null);
    const [idExt, setIdExt] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [status, setStatus] = useState(0);
    const [loadList, setLoadList] = useState([]);

    const fetchData = useCallback((pIdGuid) => {
        let apiURL = apiRoot + "/Projects/GetProjectById/" + pIdGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIdGuid(response.data.IdGuid);
                setName(response.data.name);
                setDesc(response.data.desc);
                setManager(response.data.manager);
                setIdExt(response.data.idExt);
                setStartDate(response.data.startDate!=null?new Date(response.data.startDate):null);
                setEndDate(response.data.endDate!=null?new Date(response.data.endDate):null);
                setStatus(response.data.status);
                setLoadList(response.data.loads);

                setIsLoading(false);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]); 
    
    
    useEffect(() => {
        if(mode === 1){
            setIdGuid(props.idGuid);
            fetchData(props.idGuid);
        }
        else{
            setIsLoading(false);
        }
    }, [mode, fetchData, props.idGuid]);  


    function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);

        let apiObj = {
            IdGuid: idGuid,
            Customer: sessionCustomer.customer,
            Name: name,
            Desc: desc,
            Manager: manager,
            IdExt: idExt,
            StartDate: startDate!=null?startDate:null,
            EndDate: endDate!=null?endDate:null,
            Status: status,
			Loads: loadList,
            modifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/Projects/UpdateProject/";
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setIsLoading(false);
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateProject Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/Projects/CreateProject";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Create Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateProject Error", error);
                setShowErrorModal(true);
            });
        }
    } 


    function addLoads(args){
        setShowLoadsModal(false);
        let curLoadList = JSON.parse(JSON.stringify(loadList));

        args.forEach((item) => {
            if(curLoadList.filter(l => l.idGuid === item.idGuid).length > 0){
                return;
            }

            //let curLoadList = loadList.filter((l) => l.idGuid !== item.idGuid);
            curLoadList.push(item);
        });
            
        setLoadList(curLoadList);
        props.setFormDirtyFx(1);
    }

    
    function removeLoad(args){
        let curLoadList = loadList.filter((l) => l.idGuid !== args.idGuid);
        setLoadList(curLoadList);
        props.setFormDirtyFx(1);
    };


    function openLoadsModal(){
        setShowLoadsModal(true);
    };


    if(hasError){
        return(
            <Error />
        );
    };
    
    
    if(isLoading){
        return(
            <Loading />
        );
    };   


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Dialog fullWidth={true} maxWidth="md" open={showLoadsModal}>
            <DialogTitle>Add Reel</DialogTitle>
            <DialogContent>
                <Box sx={{minHeight:"400px"}}>
                    <ProjectsEditUnassignedLoadsLookup display={display} addLoadsFx={addLoads} setShowLoadsModalFx={setShowLoadsModal} />
                </Box>            
            </DialogContent>
        </Dialog>  
        <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form id="projectForm" onSubmit={handleSubmit}>
                <Box maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box id="smBox" maxWidth="sm" sx={{width:smallForm?'auto':'600px'}}>
                <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Project Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {props.setFormDirtyFx(1); setName(e.target.value);}}
                />
                <TextField
                    id="tbDescription"
                    margin="normal"
                    fullWidth
                    label="Description"
                    size="small"
                    value={desc}
                    onChange={(e) => {props.setFormDirtyFx(1); setDesc(e.target.value);}}
                />
                <ProjectsEditManagerList manager={manager} setManagerFx={setManager} setFormDirtyFx={props.setFormDirtyFx} />
                <TextField
                    id="tbIdExt"
                    margin="normal"
                    fullWidth
                    label="External Id"
                    size="small"
                    value={idExt}
                    onChange={(e) => {props.setFormDirtyFx(1); setIdExt(e.target.value);}}
                />
                <Grid container spacing={1} sx={{mt:1, mb:1}}>
                    <Grid item xs={6}>
                        <DatePicker
                            id="tbStartDate"
                            label="Start Date"
                            inputFormat="MM/dd/yyyy"
                            value={startDate}
                            onChange={(val) => {props.setFormDirtyFx(1); setStartDate(val)}}
                            renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                            slotProps={{ field: { clearable: true } }}
                        />      
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        <DatePicker
                            id="tbEndDate"
                            label="End Date"
                            inputFormat="MM/dd/yyyy"
                            value={endDate}
                            onChange={(val) => {props.setFormDirtyFx(1); setEndDate(val)}}
                            renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                            slotProps={{ field: { clearable: true } }}
                        />  
                    </Grid>
                </Grid>
                <ProjectsEditStatus status={status} setStatusFx={setStatus} setFormDirtyFx={props.setFormDirtyFx} />
                </Box>
                </Box>
                <Box sx={{mt:2}}>
                    <Grid container sx={{mb:1}}>
                        <Grid item xs={6}>
                            <Typography variant='h6'>Project Reels</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign:'right'}}>
                            <Button variant='contained' onClick={openLoadsModal}>Add Reels</Button>
                        </Grid>
                    </Grid>
                    <ProjectsEditLoadsList data={loadList} removeLoadFx={removeLoad} />
                </Box>
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained" disabled={props.formDirty===1?false:true} type="submit" sx={{mr:2}}>Save</Button>
               </Box>
            </form>
            </LocalizationProvider>
        </Box>
        </>
    );

}
