import {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { format, parseISO } from 'date-fns';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import LoadListListItemReel from "./LoadListListItemReel";
import LoadListListItemCurLocation from './LoadListListItemCurLocation';
import LoadListListItemLinkRecordPull from "./LoadListListItemLinkAddPull";
import LoadListListItemLinkMark from "./LoadListListItemLinkMark";
import LoadListListItemLinkDetails from "./LoadListListItemLinkDetails";
import LoadListListItemLinkSetLocation from "./LoadListListItemLinkSetLocation";
import LoadListListItemIndexLength from "./LoadListListItemIndexLength";
import LoadListListItemIndexAge from "./LoadListListItemIndexAge";
import LoadListListItemIndexReturned from "./LoadListListItemIndexReturned";
import LoadListListItemProduct from "./LoadListListItemProduct";
import LoadListListItemLoadPin from "./LoadListListItemLoadPin";
import LoadListListItemProject from "./LoadListListItemProject";

import HelpProduct from "./HelpProduct";
import HelpReel from "./HelpReel";
import HelpActions from "./HelpActions";

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadListListItem(props){
    console.debug("RTS Break LoadListListItem");

    const {sessionCustomer} = useSessionContext();

    const[helpProduct, setHelpProduct] = useState(false);
    const[helpReel, setHelpReel] = useState(false);
    const[helpActions, setHelpActions] = useState(false);

    const[data, setData] = useState(null);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    function hideLinkDetails(){
        if(data!==null){
            if(props.hideDetails===null){
                return false;
            }
            else{
                return props.hideDetails;    
            }
        }
        else{
            return true;
        }
    }


    function hideLinkPulls(){
        if(data!==null){
            if(data.status===1 && sessionCustomer.accessLevel !== 25){
                return false;
            }
            else{
                return true;
            } 
        }
        else{
            return true;
        }
    }
    
    
    function hideLinkSetLocation(){
        if(data!==null && sessionCustomer.accessLevel > 25){
            if(data.tracker===null){
                if(data.status===1){
                    return false;
                }
                else{
                    return true;
                }
            }
            else{
                return true;
            }            
        }
        else{
            return true;
        }
    }

    
    function hideLinkMark(){
        if(data!==null && sessionCustomer.accessLevel > 25 && data.reel!==null){
            if(data.status===1 || data.status===25){
                return false;
            }
            else{
                return true;
            }
        }
        else{
            return true;
        }
    }


    function getCurrentOwner(){
        if(data.contractToLink!=null){
            return data.contractToLink.name;
        }
        else{
            if(data.assignedToLink!=null){
                return data.assignedToLink.name;
            }
            else{
                return data.soldToCustomer.name;
            }
        }
    }


    const styleHeaders = {
        textAlign: 'center',
        borderBottom: '2px solid #1976d2'
    }

    return (
        data !== null
        ?
        <Paper elevation={4} sx={{p:2, boxShadow:"0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,1), 0px 1px 10px 0px rgba(0,0,0,0.22)"}} id="loadListItemPaper">
            <Dialog open={helpProduct} onClose={()=>{setHelpProduct(false);}}>
                <DialogTitle>Product Help</DialogTitle>
                <DialogContent>
                    <HelpProduct />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setHelpProduct(false);}}>Close</Button>
                </DialogActions>
            </Dialog> 
            <Dialog open={helpReel} onClose={()=>{setHelpReel(false);}}>
                <DialogTitle>Reel Help</DialogTitle>
                <DialogContent>
                    <HelpReel />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setHelpReel(false);}}>Close</Button>
                </DialogActions>
            </Dialog> 
            <Dialog open={helpActions} onClose={()=>{setHelpActions(false);}}>
                <DialogTitle>Actions Help</DialogTitle>
                <DialogContent>
                    <HelpActions />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setHelpActions(false);}}>Close</Button>
                </DialogActions>
            </Dialog>
            {data.lockedMovement === 1
                ?
                    <Box>
                        <Typography variant='h6' color='red'>LOCKED MOVEMENT</Typography>
                    </Box>
                :
                    ''
            }
            <Grid container>          
                <Grid item xs={9} sx={{textAlign:'left'}}>
                    <Typography variant="h6" sx={{fontWeight:"bold", fontSize:"1em"}}>{getCurrentOwner()}</Typography>
                </Grid>
                <Grid item xs={3} sx={{textAlign:'right'}}>
                    <LoadListListItemLoadPin data={data} setDataFx={setData} />
                </Grid>
            </Grid>
            {
                data.status===25
                    ?
                    <Box>
                        <Typography variant="subtitle2" sx={{color:'red'}}>Marked for Pickup on {format(parseISO(data.markDate), "MM-dd-yyyy")}</Typography>
                    </Box>
                    :
                    ""
            }
            {
                data.status===50
                    ?
                    <Box>
                        <Typography variant="subtitle2" sx={{color:'red'}}>Marked for Pickup on {format(parseISO(data.markDate), "MM-dd-yyyy")} Listed on Pickup Request</Typography>
                    </Box>
                    :
                    ""
            }            
            {
                data.status===75
                    ?
                    <Box>
                        <Typography variant="subtitle2" sx={{color:'red'}}>Submitted for Pickup on {format(parseISO(data.pickupRequestDate), "MM-dd-yyyy")}</Typography>
                    </Box>
                    :
                    ""
            }
            {
                data.status===100
                    ?
                    <Box>
                        <Typography variant="subtitle2" sx={{color:'red'}}>Returned on {format(parseISO(data.returnedDate), "MM-dd-yyyy")}</Typography>
                    </Box>
                    :
                    ""
            }            
            <Grid container spacing={2} id="mainGrid">
                <Grid item xs={12} sm={7} md={props.hideLinks!==true?6:8} lg={props.hideLinks!==true?7:9}>
                    <Stack>
                        <Box sx={{...styleHeaders, display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Typography variant="h6">Product</Typography>
                            <Link onClick={() => {setHelpProduct(true);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                                <HelpOutlineIcon sx={{fontSize:'1em', ml:1}}  />
                            </Link>                            
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={6} lg={4}>
                                <LoadListListItemProduct data={data} />
                            </Grid>

                            <Grid item xs={6} lg={4}>
                                <Stack>
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Purchased:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{data.amt.toLocaleString()}</Typography>
                                        <Typography variant="body2" sx={{ml:.5}}>ft</Typography>
                                        {data.uom === "lb"
                                        ?
                                        <Typography variant="caption" sx={{ml:2}}>(calc)</Typography>
                                        :
                                        ""
                                        }
                                    </Box>
                                    {props.hideLinks===true
                                    ?
                                    <Box>
                                        <Box sx={{display:'flex'}}>
                                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Gross Weight:</Typography>
                                            <Typography variant="body2" sx={{ml:'2px'}}>{data.grossWeight.toLocaleString()}</Typography>
                                            <Typography variant="body2" sx={{ml:'2px'}}>lbs</Typography>
                                        </Box>
                                        <Box sx={{display:'flex'}}>
                                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Net Weight:</Typography>
                                            <Typography variant="body2" sx={{ml:'2px'}}>{data.netWeight.toLocaleString()}</Typography>
                                            <Typography variant="body2" sx={{ml:'2px'}}>lbs</Typography>
                                        </Box>
                                    </Box>                                    
                                    :
                                    ""
                                    }
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Purchase Order:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{data.customerPo==null?"No Order":data.customerPo}</Typography>
                                    </Box>
                                    {props.hideLinks===true
                                    ?
                                    <>
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Shipping Order:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{data.idSapOrder}</Typography>
                                    </Box>
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Packing List No:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{data.idSapPackingList}</Typography>
                                    </Box>
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Bill of Lading No:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{data.idSapBol}</Typography>
                                    </Box>
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Batch No:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{data.idSapBatchNo}</Typography>
                                    </Box>                                    
                                    </>
                                    :
                                    ""
                                    }
                                    <LoadListListItemProject data={data} childIdx={props.childIdx} openChildFx={props.openChildFx} />
                                    <LoadListListItemCurLocation  data={data} childIdx={props.childIdx} openChildFx={props.openChildFx} />
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Ship Date:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{(new Date(data.shipDate)).toLocaleDateString("en-US")}</Typography>
                                    </Box>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <Box sx={{display:'flex', justifyContent:'space-evenly'}}>
                                {
                                data.status<75
                                ?
                                    <>
                                    <Box sx={{mr:2}}>
                                        <Stack sx={{textAlign:'center'}}>   
                                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Length Index</Typography>
                                            <LoadListListItemIndexLength data={data} hideLinks={props.hideLinks} />
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Stack sx={{textAlign:'center'}}>
                                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Age Index</Typography>
                                            <LoadListListItemIndexAge data={data} hideLinks={props.hideLinks} />
                                        </Stack>
                                    </Box>
                                    </>
                                :
                                    <>
                                    <Box sx={{mr:2}}>
                                        <Stack sx={{textAlign:'center'}}>   
                                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Return Details</Typography>
                                            <LoadListListItemIndexReturned data={data} hideLinks={props.hideLinks} />
                                        </Stack>
                                    </Box>
                                    </>                                
                                }
                                </Box>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
                
                <Grid item xs={12} sm={5} md={props.hideLinks!==true?3:4} lg={3}>
                    <Stack>
                        <Box sx={{...styleHeaders, display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Typography variant="h6">Reel</Typography>
                            <Link onClick={() => {setHelpReel(true);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                                <HelpOutlineIcon sx={{fontSize:'1em', ml:1}}  />
                            </Link>                            
                        </Box>
                        {/* {
                            data.reel!==null
                            ? */}
                            <LoadListListItemReel data={data} />
                            {/* :
                            ""
                        } */}
                    </Stack>                          
                </Grid>

                {props.hideLinks!==true
                ?
                <Grid item xs={12} sm={12} md={3} lg={2}>
                    <Stack spacing={1}>
                        <Box sx={{...styleHeaders, display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Typography variant="h6">Actions</Typography>
                            <Link onClick={() => {setHelpActions(true);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                                <HelpOutlineIcon sx={{fontSize:'1em', ml:1}}  />
                            </Link>                            
                        </Box>
                        {hideLinkPulls()!==true
                        ?
                        <Box>
                            <LoadListListItemLinkRecordPull idGuid={data.idGuid} />
                        </Box>
                        :
                        ""
                        }

                        {hideLinkMark()!==true
                        ?
                        <Box>
                            <LoadListListItemLinkMark idGuid={data.idGuid} status={data.status} />
                        </Box>
                        :
                        ""
                        }

                        {hideLinkDetails()!==true
                        ?
                        <Box>
                            <LoadListListItemLinkDetails idGuid={data.idGuid} />
                        </Box>
                        :
                        ""
                        }                    
                            
                        {hideLinkSetLocation()!==true
                        ?
                        <Box>
                            <LoadListListItemLinkSetLocation childIdx={props.childIdx} openChildFx={props.openChildFx} />
                        </Box>
                        :
                        ""
                        }                    
                    </Stack>
                </Grid>
                :
                ""
                }
            </Grid>
        </Paper>
        :
        ''
    )
}

