import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import EditBack from '../components/EditBack';
import CrewMembersEdit from '../components/CrewMembers/CrewMembersEdit';

export default function CrewMembersPageEdit( props ){

    const [formDirty, setFormDirty] = useState(0);

    useEffect(() => {
        props.setIsModalFx(true);
    }, [props]); 

    return(
        <Container component="main" maxWidth="md" sx={{mb:5}}>
            <CssBaseline />
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty} />
                {formDirty===0
                ?
                    <Typography variant="h5" >Add Crew Member</Typography>
                :
                    <Typography variant="h5" sx={{fontStyle:'italic'}}>Add Crew Member *</Typography>
                }
            </Box>
            <Box>
                <CrewMembersEdit formDirty={formDirty} setFormDirtyFx={setFormDirty} />
            </Box>
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty} />
            </Box>                
        </Container>        
    );
}
