import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit'; 
import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';


export default function ReelsInspectionList(props){
    //console.debug("RTS Break ReelsInspectionList");

    const {userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [data, setData] = useState([]);

    const fetchData = useCallback((pIdGuid) => {
        let apiURL = apiRoot + "/ReelInspections/GetInspectionsByReelId/" + pIdGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
                setIsLoading(false);
            }
            else{
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        fetchData(props.idGuid);
    }, [fetchData, props.idGuid]); 


    function handleClick(e, cellValues){
        let reel ={
            idGuid:cellValues.row.reel.idGuid, 
            reelTypeName:cellValues.row.reel.reelType.name, 
            serialNumber:cellValues.row.reel.serialNumber
        }       
        history("/ReelInsepctionsPageEdit", {state:{reel:reel, idGuid:cellValues.row.idGuid, mode:1}});
    }


    const columns = [
        {
            field: '',
            description:"Edit Inspection", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <EditIcon />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'createDate', 
            headerName: "Inspection Date", 
            description:"Inspection Date", 
            flex:2,           
            valueGetter: (value, row) => {
                if(value == null){
                    return '';
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },
        },
        {
            field: 'inspector', 
            headerName: "Inspector", 
            description:"Reel Inspector", 
            flex:3,
            valueGetter: (value, row) => {
                return row.appUser !== null?row.appUser.firstName + ' ' + row.appUser.lastName:null;
            },             
        },
        {
            field: 'inspType', 
            headerName: "Type", 
            description:"Inspection Type", 
            flex:3,
            valueGetter: (value, row) => {
                return row.inspectionType===100?'Final Inspection':'Damage Inspection';
            },             
        },                    
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {}
        }
        else{
            return {}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };    


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <>
        <Box sx={{textAlign:'left', mt:1}}>
            <Typography variant="h6" sx={{display:'inline'}}>Serial Number:</Typography>
            <Typography inline variant="h6" sx={{display:'inline', ml:1}}>{props.display}</Typography>
        </Box>
        <Box sx={{width:'100%', height:'100%', minHeight:'400px'}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                
            />
        </Box>
        </>        
    );
}
