import {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { QrReader } from 'react-qr-reader';

import Loading from '../components/Loading';
import Error from '../components/Error';

import { useSessionContext } from '../contexts/SessionContext';

import EditCancel from '../components/EditCancel';
import { Typography } from '@mui/material';

export default function QRScanPage( props ) {
    //console.debug("RTS Break QRScanPage");

    const history = useNavigate();
    const location = useLocation();
    const {user, sessionCustomer} = useSessionContext();

    const loads = location.state.data;
    const [qrFound, setQRFound] = useState(false);
    const [trackerId, setTrackerId] = useState('');
    //const [tracker, setTracker] = useState(null);
    const [trackerFound, setTrackerFound] = useState(false);
    const [reelId, setReelId] = useState('');
    const [reel, setReel] = useState(null);
    const [reelFound, setReelFound] = useState(false);

    const [hasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        props.setIsModalFx(true);
        setIsLoading(false);
    }, [props]); 


    function handleSubmit(e) {
        e.preventDefault();
        history("/LoadList");

        let filterObj = {"user":user, 
            "status":[],
            "customers":[sessionCustomer.customer],
            "orders":[], 
            "projects":[], 
            "products":[], 
            "reelTypes":[],
            "reels":[reel], 
            "sortBy":0 
            };
    
        history("/LoadList", {state:{filter:filterObj}});
    } 
    
    
    function onQRScanResult(result, error){
        if (!!result) {
            let curData = result?.text;

            if(curData.length > 0){
                setQRFound(true);

                let curQR = curData.substr(0, curData.indexOf(","));
                loads.forEach(function(curLoad){
                    if(curLoad.tracker){
                        if(curLoad.tracker.esn === curQR){
                            //setTracker(curLoad.tracker);
                            setTrackerId(curQR);
                            setTrackerFound(true);
                        }
                    }
                });

                loads.forEach(function(curLoad){
                    if(curLoad.tracker){
                        if(curLoad.tracker.esn === curQR){
                            setReel(curLoad.reel);
                            setReelId(curLoad.reel.serialNumber);
                            setReelFound(true);
                        }
                    }
                });
            }
        }
        
        if (!!error) {
            console.info(error);
        }        
    }

 
    function onClearClicked(e){
        setQRFound(false);
        setTrackerId('');
        //setTracker(null);
        setTrackerFound(false);
        setReelId('');
        setReel(null);
        setReelFound(false);
    }


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return(
        <Container maxWidth="xs">
            <Box sx={{marginTop: 2}}>

                <QrReader
                    onResult = {(result, error) => onQRScanResult(result, error)}
                    constraints={{ facingMode: 'environment' }}
                    containerStyle={{backgroundColor:qrFound===true?'green':'red'}}
                    style={{ width: '100%' }}
                />

                <Box sx={{textAlign:'center', mt:1}}>
                    {
                    qrFound===true
                    ?
                    <Button variant="contained"  type="button" onClick={onClearClicked} >
                        Clear
                    </Button>
                    :
                    <Button variant="contained"  type="button" disabled={!qrFound} >
                        Clear
                    </Button>
                    }                
                </Box>                 

                <form id="qrScanForm" onSubmit={handleSubmit}>
                    <TextField
                        id="tbIdTracker"
                        margin="normal"
                        fullWidth
                        label="Tracker Id"
                        size="small"
                        value={trackerId}
                        onChange={(e) => {setTrackerId(e.target.value);}}
                        disabled
                    />

                    {qrFound===true
                    ?
                        trackerFound===true
                        ?
                        <Typography sx={{color:'green'}}>Tracker Found</Typography>
                        :
                        <Typography sx={{color:'red'}}>Tracker Not Found</Typography>
                    :
                    <Typography> </Typography>
                    }

                    <TextField
                        id="tbIdReel"
                        margin="normal"
                        fullWidth
                        label="Reel"
                        size="small"
                        value={reelId}
                        onChange={(e) => {setReelId(e.target.value);}}
                        disabled
                    />
                    {qrFound===true
                    ?
                        reelFound===true
                        ?
                        <Typography sx={{color:'green'}}>Reel Found</Typography>
                        :
                        <Typography sx={{color:'red'}}>Reel Not Found</Typography>
                    :
                    <Typography> </Typography>
                    }                                        

                    <Box sx={{textAlign:'center', mt:3}}>
                        <Button variant="contained"  type="submit" sx={{mr:2}} disabled={!reelFound} >
                            Show Reel
                        </Button>                
                        <EditCancel />
                    </Box>                
                </form>
            </Box>
        </Container>
    );
}
