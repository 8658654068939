import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../components/Loading';
import Error from '../components/Error';
import NamedLocationsFilter from '../components/NamedLocations/NamedLocationsFilter';
import NamedLocationsList from '../components/NamedLocations/NamedLocationsList';
import NamedLocationsHeader from '../components/NamedLocations/NamedLocationsHeader';

import { useSessionContext } from '../contexts/SessionContext';

export default function NamedLocationsPage( props ){
    //console.debug("RTS Break NamedLocationsPage");

    const {curFilter, user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [filter, setFilter] = useState();
    const [data, setData] = useState([]);

  
    const fetchData = useCallback((pFilter) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/NamedLocations/GetLocationsSummary";
        axios
        .post(
            apiURL,
            pFilter,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);
    

    useEffect(() => {
        props.setIsModalFx(false);
        if(curFilter != null && curFilter.key === 'NamedLocationsPage'){
            setFilter(curFilter.filter);
            fetchData(curFilter.filter);
        }
        else{
            setFilter({user:user, customer:sessionCustomer.customer, manager:null, sortBy:0});
            fetchData({user:user, customer:sessionCustomer.customer, manager:null, sortBy:0})
        }
    }, [props, user, sessionCustomer, curFilter, fetchData]); 

    
    const setTableHeight = () => {
        let retValue;

        if(smallForm){
            retValue = 'auto';
        }
        else{
            retValue = '75vh';
        }
        return retValue;
    };    


    if(hasError){
        return(
            <Error />
        );
    };

    if(isLoading){
        return(
            <Loading />
        );
    };

    return(
        <Box id="pageBox">
            <NamedLocationsHeader />
            <NamedLocationsFilter filter={filter} setFilterFx={setFilter}/>
            <Box sx={{mt:2, height:setTableHeight(), minHeight:'500px'}}>           
               <NamedLocationsList data={data} />
            </Box>
        </Box>
    );
}
