import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import EditCancel from '../EditCancel';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CrewMembersRemove(props) {
    //console.debug("RTS Break CrewMembersRemove");

    const {sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    const history = useNavigate();
  
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle] = useState("Validation Error");


    function handleSubmit(e) {
        e.preventDefault();

        let user = props.data;

        var filtCust = user.appUserCustomers.filter(filterCustomers);
        user.appUserCustomers = filtCust;

        let apiObj = user;

        let apiURL = apiRoot + "/AppUsers/UpdateAppUser";
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}        
        )
        .then(response => {
            history(-1);
        })
        .catch(error => {
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS CreateUser Error", error);
            setShowErrorModal(true);

        });
    } 


    function filterCustomers(value){
        return value.customer.idGuid !== sessionCustomer.customer.idGuid;
    }


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Container>
		    <Typography variant="h5" sx={{my:2}}>Remove Crew Member?</Typography>
            <form id="requestForm"onSubmit={handleSubmit}>
                <Box>
                    <Typography>{sessionCustomer.customer.name}</Typography>
                    <Typography>{props.data.firstName + ' ' + props.data.lastName}</Typography>
                    <Box  sx={{textAlign:'center', mt:2}}>
                        <Button variant="contained" type="submit" sx={{mr:2}} onClick={handleSubmit}>Remove</Button>
                        <EditCancel />
                    </Box>
                </Box>                    
            </form>
        </Container>
        </>
    );
}