import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { format, parseISO } from 'date-fns';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Error from '../Error';
import Loading from '../Loading';
import PickupRequestEditMarkedLoadsLookup from './PickupRequestsEditMarkedLoadsLookup';
import PickupRequestLoadsList from './PickupRequestLoadsList';
import EditRegion from '../EditRegion';

import { useSessionContext } from '../../contexts/SessionContext';

export default function PickupRequestsEdit(props) {
    //console.debug("RTS Break PickupRequestsEdit");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    //const display = props.display;
    const mode = props.mode; 
    
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [idGuid, setIdGuid] = useState(null);
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [altPhoneNumber, setAltPhoneNumber] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [hours, setHours] = useState('');
    const [daysAvailable, setDaysAvailable] = useState('');
    const [notes, setNotes] = useState('');
    const [siteCloseDate, setSiteCloseDate] = useState(null);
    const [loads, setLoads] = useState([]);
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');
    const [submitDate, setSubmitDate] = useState(null);
    const [submitUser, setSubmitUser] = useState(null);


    const [showLoadsModal, setShowLoadsModal] = useState(false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [showPostSubmitModal, setShowPostSubmitModal] = useState(false);

    const [locationValid, setLocationValid] = useState(false);


    const fetchData = useCallback((idGuid) => {
        let apiURL = apiRoot + "/PickupRequest/GetPickupRequestById/" + idGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIdGuid(response.data.idGuid);
                setName(response.data.name);
                setContact(response.data.onSiteContactName);
                setPhoneNumber(response.data.phoneNumber);
                setAltPhoneNumber(response.data.altPhoneNumber);
                setContactEmail(response.data.contactEmail);
                setAddress1(response.data.address1);
                setAddress2(response.data.address2);
                setCity(response.data.city);
                setRegion(response.data.region);
                setZipCode(response.data.zip);
                setHours(response.data.shipRecHours);
                setDaysAvailable(response.data.daysAvailable);
                setNotes(response.data.notes);
                setSiteCloseDate(response.data.siteCloseDate==null?new Date(response.data.siteCloseDate):null);
                setLoads(response.data.loads);
                setLat(response.data.lat);
                setLng(response.data.lng);
                setSubmitDate(response.data.submitDate);
                setSubmitUser(response.data.submitUser);

                setIsLoading(false);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);     


    useEffect(() => {
        if(mode === 1){
            setIdGuid(props.idGuid);
            fetchData(props.idGuid);
        }
        else{
            setIsLoading(false);
        }
    }, [mode, fetchData, props.idGuid]);  

   
    function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);

        let apiObj = {
            IdGuid: idGuid,
            Customer:sessionCustomer.customer,
            Name: name,
            OnSiteContactName: contact,
            PhoneNumber: phoneNumber,
            AltPhoneNumber: altPhoneNumber,
            ContactEmail: contactEmail,
            ShipRecHours: hours,
            DaysAvailable: daysAvailable,
            Notes: notes,
            SiteCloseDate: siteCloseDate,
            Lat: lat,
            Lng: lng,      
            Address1: address1,
            Address2: address2,
            City: city,
            Region: region,
            Zip: zipCode,
            Country: null,    
            SubmitUser: null,
            SubmitDate: null,
            Loads: loads,
            status: 50,
            modifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/PickupRequest/UpdatePickupRequest";
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setIsLoading(false);
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdatePickupRequest Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/PickupRequest/CreatePickupRequest";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setIsLoading(false);
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreatePickupRequest Error", error);
                setShowErrorModal(true);
            });
        }
    }; 


    function confirmSubmit(){
        setIsLoading(true);

        if(loads.length <= 0){
            setErrorTitle("No Reels Detected");
            setErrorBody("Reels must be associated with the Picup Request prior to submitting.");
            setShowErrorModal(true);
            return;    
        }

        let apiObj = {
            idGuid: idGuid,
            Customer:sessionCustomer,
            Name: name,
            OnSiteContactName: contact,
            PhoneNumber: phoneNumber,
            AltPhoneNumber: altPhoneNumber,
            ContactEmail: contactEmail,
            ShipRecHours: hours,
            DaysAvailable: daysAvailable,
            Notes: notes,
            SiteCloseDate: siteCloseDate,
            Lat: lat,
            Lng: lng,      
            Address1: address1,
            Address2: address2,
            City: city,
            Region: region,
            Zip: zipCode,
            Country: null,    
            Loads: loads,
            status: 75,
            modifyUser: user.userName,
            submitUser: user.userName
        }

        let apiURL = apiRoot + "/PickupRequest/UpdatePickupRequest";
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            history(-1);
        })
        .catch(error => {
            setShowSubmitModal(false);
            setIsLoading(false);
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS SubmitPickupRequest Error", error);
            setShowErrorModal(true);
        });
    };

    
    function addLoads(args){
        setShowLoadsModal(false);
        setIsLoading(true);
        let curLoadList = JSON.parse(JSON.stringify(loads));

        args.forEach((item) => {
            if(curLoadList.filter(l => l.idGuid === item.idGuid).length > 0){
                return;
            }

            if(item.tracker !== null){
                if(item.tracker.locations !== null && item.tracker.locations.length > 0){
                    if(item.tracker.locations[0].lat != null && item.tracker.locations[0].lng != null){
                        if(!locationValid){
                            setLat(item.tracker.locations[0].lat);
                            setLng(item.tracker.locations[0].lng);
                            setAddress1(item.tracker.locations[0].address1);
                            setAddress2(item.tracker.locations[0].address2);
                            setCity(item.tracker.locations[0].city);
                            setRegion(item.tracker.locations[0].region);
                            setZipCode(item.tracker.locations[0].zip);
                            setLocationValid(true);
                        }
                        else{
                            let dist = calcDistance (lat, lng, item.tracker.locations[0].lat, item.tracker.locations[0].lng)

                            if(dist > .5){
                                setErrorBody("Reel " + item.serialNumber + " does not appear to be located at the pickup location." );
                                setErrorTitle("Reel Location Issue");
                                item.valError = "Reel does not appear to be located at the pickup location";
                            }
                        }
                    }
                }
            }

            //let curLoadList = loadList.filter((l) => l.idGuid !== item.idGuid);
            curLoadList.push(item);
        });
            
        setLoads(curLoadList);
        props.setFormDirtyFx(1);
        setIsLoading(false);
    };


    function calcDistance (baseLat, baseLng, loadLat, loadLng){
        let radBaseLat = (baseLat * Math.PI)/180;
        let radBaseLng = (baseLng * Math.PI)/180;
        let radLoadLat = (loadLat * Math.PI)/180;
        let radLoadLng = (loadLng * Math.PI)/180;
      
        // Radius of the Earth in kilometers
        let radius = 6571;
      
        // Haversine equation
        let distanceInKilometers = Math.acos(Math.sin(radBaseLat) * Math.sin(radLoadLat) +
                Math.cos(radBaseLat) * Math.cos(radLoadLat) * Math.cos(radBaseLng - radLoadLng)) * radius;

        return distanceInKilometers;
    };


    function removeLoad(args){
        let curLoadList = loads.filter((l) => l.idGuid !== args.idGuid);
        setLoads(curLoadList);
        props.setFormDirtyFx(1);
    };


    if(hasError){
        return(
            <Error />
        );
    };


    if(isLoading){
        return(
            <Loading />
        );
    };


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={showLoadsModal}>
            <DialogTitle>Add Reel</DialogTitle>
            <DialogContent>
                <Box sx={{minHeight:"400px"}}>
                    <PickupRequestEditMarkedLoadsLookup addLoadsFx={addLoads} setShowLoadsModalFx={setShowLoadsModal} />
                </Box>            
            </DialogContent>
        </Dialog>
        <Dialog open={showSubmitModal} onClose={()=>{setShowSubmitModal(false);}}>
            <DialogTitle>Confirm Pickup Request</DialogTitle>
            <DialogContent>
                <DialogContentText>Please confirm you wish to submit this Pickup Request to Southwire.  Once confirmed this Pickup Request cannot be changed.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {confirmSubmit()}}>Confirm</Button>
                <Button variant="contained" onClick={e => {setShowSubmitModal(false);}}>Cancel</Button>
            </DialogActions>
        </Dialog> 
        <Dialog open={showPostSubmitModal} onClose={()=>{setShowPostSubmitModal(false);}}>
            <DialogTitle>Pickup Request Submitted</DialogTitle>
            <DialogContent>
                <DialogContentText>This Pickup Request has already been submitted to Southwire.  In order to make changes please contact your Southwire representative.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowPostSubmitModal(false);}}>Cancel</Button>
            </DialogActions>
        </Dialog> 
        <Box>
            {mode===1 && loads.length > 0
            ?
                submitDate==null
                ?
                <Box sx={{textAlign:"right", mb:2}}>
                    <Button variant="contained" onClick={e =>{setShowSubmitModal(true);}} disabled={props.formDirty===1?true:false}>Submit Request</Button>
                </Box>
                :
                <Box sx={{textAlign:"right", mb:2}}>
                    <Typography variant="h6">Submitted On: {format(parseISO(submitDate), "MM-dd-yyyy")}</Typography>
                    <Typography variant="h6"> By: {submitUser}</Typography>
                    <Button variant="contained" onClick={e =>{setShowPostSubmitModal(true);}}>Modify Request</Button>
                </Box>
            :
            ""
            }            
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form id="projectForm" onSubmit={handleSubmit}>
                <Box maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box id="smBox" maxWidth="sm" sx={{width:smallForm?'auto':'600px'}}>
                <Box>
                    <Typography>{sessionCustomer.customer.name}</Typography>
                </Box>
                <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Request Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {props.setFormDirtyFx(1); setName(e.target.value);}}
                    disabled={submitDate==null?false:true}
                />           
                <TextField
                    id="tbContact"
                    margin="normal"
                    fullWidth
                    label="On-Site Contact"
                    size="small"
                    required
                    value={contact}
                    onChange={(e) => {props.setFormDirtyFx(1); setContact(e.target.value);}}
                    disabled={submitDate==null?false:true}
                />
                <TextField
                    id="tbPhoneNumber"
                    margin="normal"
                    fullWidth
                    label="Phone Number"
                    size="small"
                    required
                    value={phoneNumber}
                    onChange={(e) => {props.setFormDirtyFx(1); setPhoneNumber(e.target.value);}}
                    disabled={submitDate==null?false:true}
                />                
                <TextField
                    id="tbAltPhoneNumber"
                    margin="normal"
                    fullWidth
                    label="Alt. Phone Number"
                    size="small"
                    value={altPhoneNumber}
                    onChange={(e) => {props.setFormDirtyFx(1); setAltPhoneNumber(e.target.value);}}
                    disabled={submitDate==null?false:true}
                />
                <TextField
                    id="tbContactEmail"
                    margin="normal"
                    fullWidth
                    label="Email"
                    size="small"
                    value={contactEmail}
                    onChange={(e) => {props.setFormDirtyFx(1); setContactEmail(e.target.value);}}
                    disabled={submitDate==null?false:true}
                />
                <Typography variant="h6" sx={{mt:2, mb:1}}>Pickup Address</Typography>
                <TextField
                    id="tbAddress1"
                    margin="none"
                    fullWidth
                    label="Street Address"
                    size="small"
                    value={address1}
                    onChange={(e) => {props.setFormDirtyFx(1); setAddress1(e.target.value);}}
                    disabled={submitDate==null?false:true}
                />
                <TextField
                    id="tbAddress2"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={address2}
                    onChange={(e) => {props.setFormDirtyFx(1); setAddress2(e.target.value);}}
                    disabled={submitDate==null?false:true}
                />
                <TextField
                    id="tbCity"
                    margin="normal"
                    fullWidth
                    label="City"
                    size="small"
                    value={city}
                    onChange={(e) => {props.setFormDirtyFx(1); setCity(e.target.value);}}
                    disabled={submitDate==null?false:true}
                />
                <Grid container>
                    <Grid item xs={6} sx={{pr:1}}>
                        <EditRegion disable={submitDate==null?0:1} region={region} setRegionFx={setRegion} />
                    </Grid>
                    <Grid item xs={6} sx={{pl:1}}>
                        <TextField
                            id="tbZip"
                            margin="normal"
                            fullWidth
                            label="Zip Code"
                            size="small"
                            value={zipCode}
                            onChange={(e) => {props.setFormDirtyFx(1); setZipCode(e.target.value);}}
                            disabled={submitDate==null?false:true}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{pr:1}}>
                        <Typography variant='caption'>Latitude: {lat}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{pl:1}}>
                        <Typography variant='caption'>Longitude: {lng}</Typography>
                    </Grid>
                </Grid>
                <TextField
                    id="tbHours"
                    margin="normal"
                    fullWidth
                    label="Shipping/Receiving Hours"
                    size="small"
                    value={hours}
                    onChange={(e) => {props.setFormDirtyFx(1); setHours(e.target.value);}}
                    disabled={submitDate==null?false:true}
                />
                <TextField
                    id="tbDaysAvailable"
                    margin="normal"
                    fullWidth
                    label="Days Available"
                    size="small"
                    value={daysAvailable}
                    onChange={(e) => {props.setFormDirtyFx(1); setDaysAvailable(e.target.value);}}
                    disabled={submitDate==null?false:true}
                />
                <DatePicker
                    id="tbSiteCloseDate"
                    label="Site Close Date"
                    inputFormat="MM/dd/yyyy"
                    value={siteCloseDate}
                    onChange={(val) => {props.setFormDirtyFx(1); setSiteCloseDate(val)}}
                    renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                    disabled={submitDate==null?false:true}
                />    
                <TextField
                    id="tbNotes"
                    margin="normal"
                    fullWidth
                    label="Notes"
                    size="small"
                    value={notes}
                    onChange={(e) => {props.setFormDirtyFx(1); setNotes(e.target.value);}}
                    disabled={submitDate==null?false:true}
                />
                </Box>
                </Box>
                <Box sx={{mt:2}}>
                    <Grid container sx={{mb:1}}>
                        <Grid item xs={6}>
                            <Typography variant='h6'>Pickup Request Reels</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign:'right'}}>
                            {submitDate==null
                            ?
                            <Button variant='contained' onClick={() =>{setShowLoadsModal(true);}}>Add Reels</Button>
                            :
                            ""
                            }
                        </Grid>
                    </Grid>
                    <PickupRequestLoadsList data={loads}  removeLoadFx={removeLoad} />
                </Box>
                <Box sx={{textAlign:'center', mt:3}}>
                    {submitDate==null
                    ?
                    <Button variant="contained"  type="submit" disabled={props.formDirty===1?false:true} sx={{mr:2}}>Save</Button>
                    :
                    ""
                    }
                </Box>
            </form>
            </LocalizationProvider>
        </Box>
        </>
    );

}
