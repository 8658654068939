import axios from 'axios';
import {useState, useEffect, useCallback, useMemo} from 'react';
import Box from "@mui/material/Box";
import Link from '@mui/material/Link';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { debounce } from "@mui/material/utils";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadListDetailsOwnershipContractToLookup(props) {
    console.debug("RTS Break LoadListDetailsOwnershipContractToLookup");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [data, setData] = useState([]);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [searchValue, setSearchValue] = useState("");
    const apiRef = useGridApiRef();

    
    const fetchData = useCallback((pFilter) => {
        let apiURL = apiRoot + "/CompanyLinks/GetCompanyLinksByCustomer/" + pFilter;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            else{
                setData([]);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);

    
    useEffect(() => {
            fetchData(sessionCustomer.customer.idGuid);
    }, [sessionCustomer.customer.idGuid, fetchData]); 


    function handleClick(e, cellValues){
        props.setContractToLinkFx(cellValues.row);
    }


    function handleClear(e, cellValues){
        setIsLoading(true);
        props.setContractToLinkFx();
    }


    function getLinkDisplay(){
        if(props.contractToLink != null){
            if(props.contractToLink.linkCustomer!=null){
                 return props.contractToLink.name + " (" + props.contractToLink.linkCustomer.idSap + ")";
            }
            else{
                return props.contractToLink.name;
            }

        }
        else{
            return '';
        }
    }


    const updateSearchValue = useMemo(() => {
        return debounce((newValue) => {
            apiRef.current.setQuickFilterValues(
            newValue.split(" ").filter((word) => word !== "")
            );
        }, 500);}, [apiRef]);


    function handleSearchValueChange(event) {
        const newValue = event.target.value;
        setSearchValue(newValue);
        updateSearchValue(newValue);
    }
  

    const columns = [
        {
            field: '',
            description:"Select", 
            flex:.5,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <AddIcon />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true,
        },          
        {
            field: 'name', 
            headerName: "Link", 
            description:"Company Link", 
            flex:4,
            hideable:false,
            disableColumnMenu:true,
            sortable:false,
        }, 
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {}
        }
        else{
            return {}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    if(hasError){
        return(
            <Error />
        );
    };
    
    
    if(isLoading){
        return(
            <Loading />
        );
    };    


    return (
        <>
        <Box sx={{display:'flex', alignItems:'center', width:'100%'}}>
            <TextField
                value={getLinkDisplay()}
                label="Current"
                size="small"
                fullwidth
                sx={{mt:1, pb:1, width:'100%'}}
                disabled
            />  
            <Box>            
                <Button onClick={handleClear} disabled={props.contractToLink==null?true:false}>
                    <ClearIcon sx={{fontSize:'2em', color:props.contractToLink==null?'gray':'red', fontWeight:'bold'}} />
                </Button>
            </Box>   
        </Box>
        <TextField
            value={searchValue}
            onChange={handleSearchValueChange}
            label="Search"
            size="small"
            fullwidth
            sx={{mt:1, pb:1, width:'100%'}}
            inputRef={input => input && input.focus()}            
        />        
        <Box width="sm" sx={{height:"400px"}}>
            <DataGridPro 
                apiRef={apiRef}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                rows={data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                          
            />
        </Box>
        </>
    );
}
