import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

import FilterListIcon from '@mui/icons-material/FilterList';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import Loading from '../Loading';
import Error from '../Error';
import LoadListFilterOrderList from './LoadListFilterOrderList';
import LoadListFilterProductList from './LoadListFilterProductList';
import LoadListFilterReelList from './LoadListFilterReelList';
import LoadListFilterReelTypeList from './LoadListFilterReelTypeList';
import LoadListFilterProjectList from './LoadListFilterProjectList';
import LoadListFilterStatusList from './LoadListFilterStatusList';
import LoadListFilterSortByList from './LoadListFilterSortByList';
import LoadListFilterNamedLocationList from './LoadListFilterNamedLocationList';

import { useSessionContext } from '../../contexts/SessionContext';


export default function LoadListFilter(props) {
    //console.debug("RTS Break LoadListFilter");

    const history = useNavigate();

    const {setCurFilter, user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [data, setData] = useState();

    const [orders, setOrders] = useState([]);
    const [products, setProducts] = useState([]);
    const [reelTypes, setReelTypes] = useState([]);
    const [reels, setReels] = useState([]);
    const [projects, setProjects] = useState([]);
    const [status, setStatus] = useState([]);
    const [minLength, setMinLength] = useState(0);
    const [namedLocations, setNamedLocations] = useState([]);
    const [pinned, setPinned] = useState(-1);
    const [locked, setLocked] = useState(-1);
    const [sortBy, setSortBy] = useState(0);

    const [showFilter, setShowFilter] = useState(true);
    const [showMore, setShowMore] = useState(true);


    const fetchFilters = useCallback(() => {
        let apiURL = apiRoot + "/LoadList/GetLoadListFilters";
        axios
        .post(
            apiURL,
            sessionCustomer.customer,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });        
    },[apiRoot, userAuthToken.token, sessionCustomer.customer]);

    
    useEffect(() => {
        fetchFilters();

        setOrders(props.filter.Orders);
        setProducts(props.filter.Products);
        setReelTypes(props.filter.ReelTypes);
        setReels(props.filter.Reels);
        setProjects(props.filter.Projects);
        setStatus(props.filter.Status);
        setMinLength(props.filter.MinLength);
        setNamedLocations(props.filter.NamedLocations);
        setPinned(props.filter.Pinned);
        setLocked(props.filter.Locked);
        setSortBy(props.filter.SortBy);

    },[fetchFilters]);    


    function setFilter(){
        setCurFilter({key:'LoadListPage', filter:{User:user, 
                        Status:status,
                        Customer:sessionCustomer.customer,
                        Orders:orders, 
                        Projects:projects, 
                        Products:products, 
                        ReelTypes:reelTypes,
                        Reels:reels,
                        MinLength:minLength===""?0:minLength,
                        NamedLocations:namedLocations,
                        Pinned:pinned,
                        Locked:locked,
                        SortBy:sortBy}
                    });

        props.setFilterFx({User:user, 
            Status:status,
            Customer:sessionCustomer.customer,
            Orders:orders, 
            Projects:projects, 
            Products:products, 
            ReelTypes:reelTypes,
            Reels:reels,
            MinLength:minLength===""?0:minLength,
            NamedLocations:namedLocations,
            Pinned:pinned,
            Locked:locked,
            SortBy:sortBy}
        );
    }

    function handleScanClick(){
        history("/QRScanPage", {state:{data:''}});
    }    


    function toggleShowFilter()
    {
        setShowFilter(!showFilter);
    }


    function toggleShowMore()
    {
        setShowMore(!showMore);
    }


    if(hasError){
        return(
            <Error />
        );
    };


    if(isLoading){
        return(
            <Loading />
        );
    };

    
    return (
        <Box sx={{pt:1}}>
            <Box sx={{visibility:showFilter===true?"visible":"hidden", display:showFilter===true?"initial":"none"}}>
                <Grid container id="gridContainer">
                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <LoadListFilterOrderList orders={orders} data={data.orders} setOrdersFx={setOrders} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <LoadListFilterProductList products={products} data={data.products} setProductsFx={setProducts} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <LoadListFilterProjectList projects={projects} data={data.projects} setProjectsFx={setProjects} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <LoadListFilterReelTypeList reelTypes={reelTypes} data={data.reelTypes} setReelTypesFx={setReelTypes} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <LoadListFilterReelList reels={reels} data={data.reels} setReelsFx={setReels} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <LoadListFilterStatusList status={status} setStatusFx={setStatus} />
                    </Grid>


                    <Grid item xs={12} sx={{px:1, pt:1}}>
                        <Grid container>
                            <Box onClick={toggleShowMore}>
                                <Button variant="text" size="small">{showMore?'+ More':'- Less'}</Button>
                            </Box>                
                        </Grid>
                    </Grid>                        

                    <Grid item xs={12} sx={{px:1, pt:1, visibility: showMore===true?"hidden":"visible", display:showMore===true?"none":"initial"}}>
                        <Grid container>
                            <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                                <TextField
                                    id="tbMinLength"
                                    margin="none"
                                    fullWidth
                                    label="Minimum Length Remaining (ft)"
                                    type="number"
                                    size="small"
                                    value={minLength}
                                    onChange={(e) => {setMinLength(e.target.value);}}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                                <LoadListFilterNamedLocationList locations={namedLocations}  data={data.namedLocations} setLocationsFx={setNamedLocations} />
                            </Grid>    

                            <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                                <LoadListFilterSortByList sortBy={sortBy} setSortByFx={setSortBy} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{px:1, pt:1}}>
                        <Grid container>
                            <Grid item xs={12} sm={6} lg={6} sx={{textAlign:{xs:'center', sm:'left'}}}>
                                <Button type="button" variant="contained" onClick={setFilter} sx={{mt:1}} >
                                    <FilterListIcon />
                                    <Typography sx={{ml:1}}>Filter</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6} sx={{textAlign:{xs:'center', sm:'right'}}}>
                                <Button type="button" variant="contained" onClick={handleScanClick} sx={{mt:1}} >
                                    <QrCodeScannerIcon />
                                    <Typography sx={{ml:1}}>Scan</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>                        
                </Grid>
                <Box onClick={toggleShowFilter}>
                    <Button variant="text" startIcon={<ArrowCircleUpOutlinedIcon />} size="small">Hide Filters</Button>
                </Box>                
            </Box>
            <Box sx={{visibility: showFilter===true?"hidden":"visible", display:showFilter===true?"none":"initial"}}>
                <Box onClick={toggleShowFilter}>
                    <Button variant="text" startIcon={<ArrowCircleDownIcon />} size="small">Show Filters</Button>
                </Box>
            </Box>                     
        </Box>
    );
}
