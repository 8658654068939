import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { format, parseISO } from 'date-fns';


export default function LoadListDetailsPullListDetail(props){
    //console.debug("RTS Break LoadListDetailsPullListDetail");

    if(props.data===null){
        return ("")
    }

    let curLat = props.data.lat===null?"Not Available":props.data.lat;
    let curLng = props.data.lng===null?"Not Available":props.data.lng;

    return (
        <Box>
            <Box sx={{display:'flex'}}>
                <Typography sx={{fontWeight:'bold'}}>Date:</Typography>
                <Typography>{format(parseISO(props.data.pullDate), 'MM-dd-yyyy')}</Typography>
            </Box>
            <Box sx={{display:'flex'}}>
                <Typography sx={{fontWeight:'bold'}}>Crew Member:</Typography>
                <Typography>{props.data.pullUser}</Typography>
            </Box>
            <Box sx={{display:'flex'}}>
                <Typography sx={{fontWeight:'bold'}}>Length Pulled:</Typography>
                <Typography>{props.data.pullLength}</Typography>
                <Typography sx={{marginLeft:"2px"}}>ft.</Typography>                
                {/* <Typography sx={{marginLeft:"2px"}}>{props.data.uom}</Typography>                 */}
            </Box>
            <Box sx={{display:'flex'}}>
                <Typography sx={{fontWeight:'bold'}}>Notes:</Typography>
                <Typography>{props.data.notes}</Typography>
            </Box>                         
            <Box sx={{pt:1}}>
                <Typography sx={{fontWeight:'bold'}}>Location</Typography>
                <Box sx={{display:'flex'}}>
                    <Typography sx={{fontWeight:'bold'}}>Latitude:</Typography>
                    <Typography>{curLat}</Typography>
                </Box>
                <Box sx={{display:'flex'}}>
                    <Typography sx={{fontWeight:'bold'}}>Longitude:</Typography>
                    <Typography>{curLng}</Typography>
                </Box>
            </Box>
            <Box sx={{pt:1}}>
                <Typography sx={{fontWeight:'bold'}}>Project</Typography>
                <Box sx={{display:'flex'}}>
                    <Typography sx={{fontWeight:'bold'}}>Name:</Typography>
                    <Typography>{props.data.project!==null?props.data.project.name:''}</Typography>
                </Box>
                <Box sx={{display:'flex'}}>
                    <Typography sx={{fontWeight:'bold'}}>Description:</Typography>
                    <Typography>{props.data.project!==null?props.data.project.desc:''}</Typography>
                </Box>
            </Box>            
            <Box sx={{pt:1}}>
                <Typography sx={{fontWeight:'bold'}}>Audit</Typography>
                <Box sx={{display:'flex'}}>
                    <Typography sx={{fontWeight:'bold'}}>Recorded By:</Typography>
                    <Typography>{props.data.createUser}</Typography>
                </Box>
                <Box sx={{display:'flex'}}>
                    <Typography sx={{fontWeight:'bold'}}>Date Recorded:</Typography>
                    <Typography>{format(parseISO(props.data.createDate), 'MM-dd-yyyy')}</Typography>
                </Box>
            </Box>            
        </Box>
    )
}
